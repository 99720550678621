<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" @closed="form=null" width="910px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="90px" label-suffix=":" v-if="form">
      <div style="width: 870px;" :class="{layout_disabled: !editable}">
        <div class="h sb">
          <el-form-item label="商品名称" prop="name" style="width: 870px;">
            <el-input v-model.trim="form.name" :maxlength="100" :readonly="$erp.enable()" />
          </el-form-item>
        </div>
      <div>
        <el-form-item label="规格" prop="specs" v-if="$erp.enable() || !editable">
             {{$goodsSpecConvert(form.specs)}}
        </el-form-item>
        <el-form-item label="规格" prop="specs" v-else>
            <el-input v-model.trim="form.specs" :maxlength="100" />
          </el-form-item>
      </div>
        <div class="h sb">
          <el-form-item label="ERP编码" prop="erpCode" style="width: 280px;" v-if="$erp.enable()">
            <el-input v-model="form.erpCode" :maxlength="40" size="mini" @change="handleERPInfo">
              <el-button slot="append" icon="fas fa-mouse-pointer" size="mini" :loading="erpLoading" @click="handleErpCodePick"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="商品编码" prop="code" style="width: 280px;">
            <el-input v-model="form.code" :maxlength="40" size="mini" />
          </el-form-item>
          <el-form-item label="条形码" prop="barCode" style="width: 280px;">
            <el-input v-model="form.barCode" :maxlength="40" size="mini" :disabled="form.isImport || !form.enableEdit" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="品牌" prop="goods.brandId" style="width: 280px;">
            <quick-select url="api/brand" v-model="form.goods.brandId" filterable placeholder="请选择品牌" style="width: 100%;" @change="form.seriesId = null" />
          </el-form-item>
          <el-form-item label="分类" prop="goods.categoryId" style="width: 280px;">
            <tree-picker v-model="form.goods.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 100%;" />
          </el-form-item>
          <el-form-item label="系列" prop="goods.seriesId" style="width: 280px;">
            <tree-picker v-if="form.goods.brandId" v-model="form.goods.seriesId" :params="{brandId:form.goods.brandId,enabled: true}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 100%;" />
            <span class="fc-g" v-else>请先选择品牌</span>
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="统一零售价" prop="retailPrice" style="width: 280px;">
            <price-input v-model="form.retailPrice" style="width: 100%;" />
          </el-form-item>
          <el-form-item label="计量单位" prop="goods.unitId" style="width: 280px;">
            <quick-select url="api/unit" v-model="form.goods.unitId" filterable placeholder="请选择计量单位" auto-select-first-option style="width: 100%;" />
          </el-form-item>
          <el-form-item label="交货周期" prop="deliveryCycle" style="width: 280px;">
            <div class="h c">
              <el-input-number type="number" v-model="form.deliveryCycle" :min="0" :max="365" :step="1" :precision="0" controls-position="right" @change="$checkNaN(form, 'deliveryCycle', 0)" class="flex" />
              <span>&nbsp;天</span>
            </div>
          </el-form-item>
        </div>
        <div class="c h">
          <el-form-item label="可使用返利" style="width: 280px;" v-if="$erp.enable()">
            <el-switch v-model="form.joinRebate" size="mini" />
          </el-form-item>
          <!-- <el-form-item label="销售目标" style="width: 280px;">
            <el-switch v-model="form.isSaleTarget" size="mini" />
          </el-form-item> -->
          <el-form-item label="上下架" style="width: 280px;" v-permission="['GOODS_ALL', 'GOODS_ONSHELF']">
            <el-switch v-model="form.onShelf" size="mini" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" v-permission="['GOODS_ALL', 'GOODS_ADD','GOODS_EDIT']">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSubmit" v-if="!$erp.enable() && editable">确认</el-button>
    </div>
    <code-picker ref="codePicker" @select="handleErpCodeChange" v-if="$erp.enable()" />
  </el-dialog>
</template>

<script>
import { getByErpCode, getSku, addSku, editSku } from "@/api/goods";
import codePicker from "../manage/editors/code-picker";

export default {
  components: { codePicker },
  data() {
    return {
      loading: false,
      saving: false,
      erpLoading: false,
      dialog: false,
      form: null,
      editable: false,
      rules: {
        erpCode: [{ required: true, message: "请输入ERP编码" }],
        name: [{ required: true, message: "请输入商品名称" }],
        // specs: [{ required: true, message: "请输入商品的规格" }],
        "goods.brandId": [{ required: true, message: "请选择品牌" }],
        "goods.unitId": [{ required: true, message: "请选择计量单位" }],
        "goods.categoryId": [{ required: true, message: "请选择分类" }],
      },
    };
  },
  computed: {
    title() {
      return this.loading
        ? "正在加载中…"
        : this.editable
        ? this.form && this.form.id
          ? "编辑商品信息"
          : "新增商品信息"
        : "查看商品信息";
    },
  },
  methods: {
    handleErpCodePick() {
      this.$refs.codePicker && this.$refs.codePicker.show();
    },
    handleErpCodeChange(row) {
      this.form.erpCode = row.erpCode;
      this.handleERPInfo(this.current);
    },
    handleERPInfo() {
      if (this.form && this.form.erpCode) {
        this.erpLoading = true;
        getByErpCode(this.form.erpCode)
          .then((res) => {
            if (res && res.skus && res.skus.length) {
              this.$confirm(
                `ERP编码【${
                  this.form.erpCode
                }】对应的ERP商品信息如下：<br />商品名称：${
                  res.name
                }<br />商品规格：${this.$goodsSpecConvert(
                  res.skus[0].specs
                )}<br />确定要使用该ERP编码吗？`,
                "ERP提示",
                {
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                }
              )
                .then((_) => {
                  this.form.name = res.name;
                  try {
                    let specs = JSON.parse(res.skus[0].specs);
                    if (specs && specs.length) {
                      this.form.specs = specs[0].value || "";
                    }
                  } catch (e) {
                    this.form.specs = "";
                  }
                })
                .catch((_) => {
                  this.form.erpCode = "";
                })
                .finally((_) => {
                  this.erpLoading = false;
                });
            } else {
              this.$confirm(
                `未能找到ERP编码【${this.form.erpCode}】对应的ERP商品信息，确定要使用该ERP编码吗？`,
                "ERP提示",
                {
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                }
              )
                .then()
                .catch((_) => {
                  this.form.erpCode = "";
                })
                .finally((_) => {
                  this.erpLoading = false;
                });
            }
          })
          .catch((_) => {
            this.form.erpCode = "";
          })
          .finally((_) => {
            this.erpLoading = false;
          });
      }
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          let fd = JSON.parse(JSON.stringify(this.form));
          fd.specs = JSON.stringify([{ name: "规格", value: fd.specs }]);
          (!!this.form.id ? editSku : addSku)(fd)
            .then((res) => {
              this.$notify({
                title: "保存商品信息成功",
                type: "success",
                duration: 2500,
              });
              this.dialog = false;
              this.$parent.init();
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    resetForm(form, editable = true) {
      this.editable = editable;
      if (form) {
        this.loading = true;
        getSku(form.id)
          .then((res) => {
            try {
              if(this.editable && !this.$erp.enable()){
                let specs = JSON.parse(res.specs);
                if (specs && specs.length) {
                  res.specs = specs[0].value || "";
                }
              }
            } catch (e) {
              res.specs = "";
            }
            this.form = res;
            if(!!!this.form.name) this.form.name = res.goods.name;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = {
          code: "",
          barcode: "",
          name: "",
          specs: "",
          retailPrice: 0,
          erpCode: "",
          entId: null,
          spuId: null,
          isRebate: false,
          isSaleTarget: true,
          onShelf: true,
          enable: true,
          joinRebate: true,
          isImport: false,
          enableEdit: true,
          deliveryCycle: null,
          goods: {
            id: null,
            categoryId: null,
            brandId: null,
            seriesId: null,
            coverImg: null,
            fileId: this.$uuid(),
            remindWords: "",
            info: "",
          },
        };
      }
      this.dialog = true;
    },
  },
};
</script>